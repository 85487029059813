<template>
  <v-row no-gutters>
    <v-col class="navBar border-right">
      <v-sheet :height="height" class="overflow-auto">
        <v-row no-gutters>
          <v-col cols="12">
            <v-row class="px-3 pt-2" no-gutters align="center">
              <v-col><h4 class="font-weight-regular">{{$tools.dateText(daySelected)}}</h4></v-col>
              <v-col cols="auto">
                <v-btn icon @click="setToday()">
                  <v-icon v-if="isToday" color="primary" small>mdi-checkbox-blank-circle</v-icon>
                  <v-icon v-else small>mdi-checkbox-blank-circle-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <!-- <v-date-picker
                v-model="daySelected"
                :show-current="true"
                :first-day-of-week="1"
                :events="usersSelectedEvents"
                no-title
                show-adjacent-months
                color="primary"
                full-width
                event-color="green lighten-1"
            /> -->
            <date-picker :daySelected.sync="daySelected" :events.sync="usersSelectedEvents" :usersSelectedEventsColors.sync="usersSelectedEventsColors" cookieName="visitsDay"/>
          </v-col>
        </v-row>
        <div class="mb-4 mx-3">
          <v-row class="rowSmall overflow-x-hidden">
            <v-col cols="12" class="pt-3">
              <v-slider
                  max="100"
                  min="0"
                  dense
                  hide-details
                  v-model="zoomSlide"
              >
                <template v-slot:prepend>
                  <v-sheet width="56">
                    <v-icon>mdi-magnify-plus-outline</v-icon><div class="caption d-inline-block">{{zoomSlide}}%</div>
                  </v-sheet>
                </template>
              </v-slider>
            </v-col>
            <v-col cols="6">
              <v-btn
                  @click="lockNew=!lockNew"
                  :class="lockNew ? 'v-btn--active' : ''"
                  block depressed
              >
                Блок
                <v-icon class="ml-2" small>mdi-lock</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn block depressed color="silver" @click="listOrGrid = !listOrGrid" v-if="!listOrGrid">
                Список <v-icon class="ml-2" small> mdi-view-list-outline</v-icon>
              </v-btn>
              <v-btn block depressed color="silver" @click="listOrGrid = !listOrGrid" v-if="listOrGrid">
                Сетка <v-icon class="ml-2" small> mdi-land-rows-vertical</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn block depressed color="silver" @click="$router.push('/visit/list/')">
                Период <v-icon class="ml-2" small> mdi-view-list-outline</v-icon>
              </v-btn>
            </v-col>
            <v-col v-if="visitCreateVisitPlan||visitMove">
              <v-btn block depressed color="primary" @click="clearDataFromCaller();visitMove=null;">
                Отменить <span v-if="visitMove">перенос</span><span v-else>визит</span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div class="px-3">
          <com-branch-autocomplete-multiple :select.sync="comBranchsSelected" globalState/>
          <spec-select :specsSelected.sync="specsSelected"/>

          <user-select type="spec" @selected="userspecSelected" cookiesName="userSpecSelect"/>

          <!-- <v-autocomplete
              v-model="usersSelected"
              :items="usersAllows"
              :search-input.sync="usersText"
              label="Специалисты"
              outlined
              dense
              hide-details="auto"
              multiple
              class="mb-4"
              item-text="name"
              item-value="id"
              :disabled="loadState.isLoading"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Не найдено</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:item="{item}">
              <v-list-item-avatar class="user">
                <img :src="item.imageUrl" alt="" v-if="item.imageUrl">
              </v-list-item-avatar>
              <v-list-item-content style="width:360px">
                <v-list-item-title>{{item.name}}</v-list-item-title>
                <v-list-item-subtitle>
                  <div v-for="userSpec in item.userSpecs" :key="item.id+'_'+userSpec.id" class="text-wrap">
                    {{userSpec.spec.name}}
                    <v-icon small>mdi-chevron-left</v-icon>
                    {{userSpec.comDiv.comBranch.name}}
                    <v-icon v-if="!$tools.isEmpty(userSpec.commentAdmin)" class="ml-2 mr-1" small>mdi-comment-text-outline</v-icon>
                    {{userSpec.commentAdmin}}
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
            <template v-slot:selection="{item}">
              <v-chip class="full-width mt-2 px-0" small style="width:95%">
                <v-row no-gutters class="align-center">
                  <v-col cols="auto">
                    <v-avatar class="mr-2 user">
                      <img :src="item.imageUrl" alt="" v-if="item.imageUrl">
                    </v-avatar>
                  </v-col>
                  <v-col class="text-overflow">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          {{item.name}}
                        </span>
                      </template>
                      <span>{{item.name}}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="auto"><v-icon small @click.stop="usersSelected=usersSelected.filter((v)=>v!==item.id)">mdi-close-circle</v-icon></v-col>
                </v-row>
              </v-chip>
            </template>
          </v-autocomplete> -->
          <service-select title="Специалисты по услугам" top @selected="serviceSelected"/>
          <v-checkbox v-model="statusActive" label="Только с активным статусом" class="mb-4" dense hide-details="auto" @click="VisitStatusesCheckbox(statusActive)"/>
          <v-autocomplete
              v-model="visitStatusSelected"
              :items="visitStatuses"
              :search-input.sync="visitStatusText"
              label="Статус"
              outlined
              dense
              hide-details="auto"
              multiple
              class="mb-4"
              item-text="name"
              item-value="id"
              :disabled="loadState.isLoading"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Не найдено</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:item="{item}">
              <v-list-item-title>
                <div class="py-2">
                  <v-avatar :color="item.color" size="12" class="mr-2"/>{{item.name}}
                </div>
              </v-list-item-title>
            </template>
            <template v-slot:selection="{item}">
              <v-chip class="full-width mt-2" small style="width:95%">
                <v-row no-gutters>
                  <v-col cols="auto"><v-avatar :color="item.color" class="circleIcon mr-2"/></v-col>
                  <v-col class="text-overflow">{{item.name}}</v-col>
                  <v-col cols="auto"><v-icon small @click.stop="visitStatusSelected=visitStatusSelected.filter((v)=>v!==item.id)">mdi-close-circle</v-icon></v-col>
                </v-row>
              </v-chip>
            </template>
          </v-autocomplete>

          <span class="caption">Создать визит с интервалом:</span>
          <v-btn-toggle class="mb-3" dense v-model="forcedVisitDuration">
            <v-btn value="5">5м</v-btn>
            <v-btn value="10">10м</v-btn>
            <v-btn value="15">15м</v-btn>
            <v-btn value="30">30м</v-btn>
            <v-btn value="60">60м</v-btn>
          </v-btn-toggle>
        </div>
      </v-sheet>
    </v-col>
    <v-col style="padding-right: 56px;">
      <v-data-table
          v-if="listOrGrid"
          :headers="[
              { text: 'Статус', sortable: false},
              { text: 'ID', sortable: false, width:'auto'},
              { text: 'Номер карты', sortable: false},
              { text: 'Кто', sortable: false},
              { text: 'Телефон', sortable: false},
              { text: 'К кому', sortable: false},
              { text: 'Специальность', sortable: false},
              { text: 'Кабинет', sortable: false},
              { text: 'Дата и время', sortable: false},
            ]"
          :items="visits"
          sort-by="begin"
          dense
          :items-per-page="-1"
          :height="height-50"
          ref="table"
          :style="{paddingRight:'56px'}"
      >
        <template v-slot:item="{item}">
          <tr v-if="!!item.user" @click="visitId=item.id;">
            <td class="text-no-wrap"><visit-status :status="$tools.findById(statuses, item.status)" :isPaidFull="item.paySummary.isPaidFull" :isSecond="item.isSecond" small/></td>
            <td>{{ item.user.id }}</td>
            <td v-if="item.user.userProfile.medCardNumber">{{item.user.userProfile.medCardNumber}}</td>
            <td v-else>{{item.user.id}}</td>
            <td>{{ item.user.lastName }} {{ item.user.firstName }} {{ item.user.secondName }} 
              <v-btn
                    text
                    icon 
                    x-small
                    @click.stop="$tools.copyToClipboard(item.user.lastName+' '+item.user.firstName+' '+item.user.secondName);$store.commit('alertSuccess', 'ФИО скопировано');"
                  >
                 <v-icon small>mdi-content-copy</v-icon> 
              </v-btn>
              <v-btn 
                  @click.stop
                  x-small
                  icon depressed :href="'/panel/user/client/'+item.user.id+'/'" target="_blank">
                <v-icon small>mdi-open-in-new</v-icon>
              </v-btn>
            </td>
            <td class="text-no-wrap">
                <call-phone :phone="item.user.phone" />
                <v-btn
                      text
                      icon
                      x-small
                      @click.stop="$tools.copyToClipboard(item.user.phone);$store.commit('alertSuccess', 'Телефон скопирован');"
                    >
                  <v-icon small>mdi-content-copy</v-icon> 
                </v-btn>
            </td>
            <td class="text-no-wrap">
              <v-avatar class="user" size="24">
                <img :src="item.userSpec.user.imageUrl" v-if="item.userSpec.user.imageUrl" alt="">
              </v-avatar>
              {{item.userSpec.user.lastName}} {{item.userSpec.user.firstName}}  {{item.userSpec.user.secondName}}
              <v-btn
                    text
                    icon 
                    x-small
                    @click.stop="$tools.copyToClipboard(item.userSpec.user.lastName+' '+item.userSpec.user.firstName+' '+item.userSpec.user.secondName);$store.commit('alertSuccess', 'ФИО скопировано');"
                  >
                 <v-icon small>mdi-content-copy</v-icon> 
              </v-btn>
              <v-btn 
                  @click.stop
                  x-small
                  icon depressed :href="'/panel/user/spec/'+item.userSpec.user.id+'/'" target="_blank">
                <v-icon small>mdi-open-in-new</v-icon>
              </v-btn>  
            </td>
            <td class="text-no-wrap">
              {{ item.userSpec.spec.name }}
            </td>
            <td class="text-no-wrap">
              {{ item.comPlace.comRoom.sid }}
            </td>
            <td class="text-no-wrap">{{$tools.dateTimeFormat(item.begin, 'DD.MM.YYYY')}} в {{$tools.dateTimeFormat(item.begin, 'HH:mm')}}</td>
          </tr>
        </template>
        <!-- <template v-slot:add-item>
          <tr>
            <td colspan="6">
              <b>Итого:</b> 
            </td>
            <td>
              <b> {{ medPlansTotalPrice }} ₽</b>
            </td>
          </tr>
        </template> -->
        <template v-slot:footer.prepend>
          <v-btn small text @click="download()">
            <v-icon small class="pr-2">mdi-download</v-icon>Скачать
          </v-btn>
          <v-spacer/>
        </template>
      </v-data-table>
      <v-sheet class="calendarSheet" v-if="!listOrGrid">
        <div class="calendarScroll" :style="{height:height+'px',right:typeof navMenuRight==='undefined' ? '' : 400+'px'}">
          <div :style="{height:height-18+'px',width:width+'px'}">
            <v-calendar
                ref="calendar"
                v-model="daySelected"
                color="primary"
                type="category"
                category-show-all
                event-overlap-mode="column"
                :categories="cats"
                :events="events"
                :event-color="getEventColor"
                :interval-format="hm"
                :interval-height="(zoomSlide*1.4)+30"
                :interval-minutes="30"
                :interval-count="intervalCountTime"
                :first-time="beginTime"
                category-text="id"
                @mouseenter:time-category="mouseMove"
                @mousemove:time-category="mouseMove"
                @mouseleave:time-category="mouseMove"
                class="visitsCalendar"
            >
              <template v-slot:category="{ category }" >
                <div v-if="typeof category.name==='undefined'"></div>
                <v-menu v-else open-on-hover bottom offset-y :open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-content v-if="category!=null">
                        <v-list-item-title>
                          <div class="position-absolute ml-1">
                            <v-avatar :color="category.comRoom.comFloor.comBuilding.comBranch.color" size="14"/>
                          </div>
                          <div class="pl-8">{{category.comRoom.comFloor.comBuilding.comBranch.name}}</div>
                          <v-row no-gutters>
                            <v-col cols="auto" class="title pr-2 font-weight-medium text-overflow">
                              {{category.comRoom.sid}}
                            </v-col>
                            <v-col class="subtitle-2 pt-2 font-weight-medium text-overflow">
                              {{ category.comRoom.name}}
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <div class="subtitle-2 font-weight-medium text-overflow">{{ category.name}}</div>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-sheet v-if="category!=null" class="py-2 px-4">
                    <div class="caption">Рабочее место</div>
                    <div class="subtitle-2">{{category.name}}</div>
                    <div class="caption">Номер кабинета / Название кабинета</div>
                    <div class="subtitle-2">{{category.comRoom.sid}} / {{category.comRoom.name}}</div>
                    <div class="caption">Этаж</div>
                    <div class="subtitle-2">{{category.comRoom.comFloor.name}}</div>
                    <div class="caption">Адрес строения</div>
                    <div class="subtitle-2">{{category.comRoom.comFloor.comBuilding.street}}</div>
                    <div class="caption">Филиал</div>
                    <div class="subtitle-2">{{category.comRoom.comFloor.comBuilding.comBranch.name}}</div>
                  </v-sheet>
                </v-menu>
              </template>
              <template v-slot:event="{ event }">
                <div v-if="event.type==='visitNew'"
                  @click="visitCreateState.modalOpen({
                    comPlace:event.comPlace,
                    begin:event.start,
                    end:event.end,
                    visitCreateVisitPlan:$tools.copy(visitCreateVisitPlan),
                    visitMove:$tools.copy(visitMove)
                  });visitCreateVisitPlan=null;visitMove=null;"
                  class="fill-height px-2 overflow-hidden">
                  <div>
                    {{ $tools.time(event.start) }}
                    {{visitMove===null ?
                      'Новый визит'+(visitCreateVisitPlan===null ? '' : '('+visitCreateVisitPlan.user.lastName+' '+visitCreateVisitPlan.user.firstName+' '+visitCreateVisitPlan.user.secondName+')') :
                      'Перенести визит c '+$tools.time(visitMove.begin)
                    }}
                  </div>
                </div>

                <div v-else-if="event.type==='lockNew'"
                  @click="visitMarkState.modalOpen({
                    comPlace:event.comPlace,
                    begin:event.start,
                    end:event.end,
                    comment:null,
                    mark:'blue',
                  });"
                  class="fill-height px-2 overflow-hidden"
                >
                  <div>{{ $tools.time(event.start) }} Блокировка времени</div>
                </div>

                <div v-else-if="typeof event.visit!=='undefined' && !$tools.isEmpty(event.visit.mark)"
                  @click="visitMarkState.modalOpen({
                    id:event.visit.id,
                    comPlace:event.visit.comPlace,
                    begin:event.start,
                    end:event.end,
                    comment:event.visit.comment,
                    mark:event.visit.mark,
                  });"
                  class="faded fill-height"
                  :style="'border-top: solid 2px '+event.visit.mark"
                  :class="{ visitTimeFinish: isFinish(event.end) }"
                >
                  <div class="fill-height full-width position-absolute"  :style="'opacity: 0.1;background:'+event.visit.mark"></div>
                  <div class="px-1 py-1 black--text">
                    <v-icon x-small :color="event.visit.mark" class="mt-n1">mdi-lock</v-icon>
                    {{event.visit.comment}}
                  </div>
                </div>

                <div v-else-if="typeof event.userSpecPlan!=='undefined'" class="userSpecPlan">
                  <user-spec-plan-calendar-view
                      :event="event"
                      :visitPlans="visitPlans"
                      :scrollTop="scrollTop"
                      :zoom="zoomSlide"
                      :state="loadState"
                      @visitPlansForUserSpec="visitPlansForUserSpec"
                      @visitPlansForUserSpecLiveList="visitPlansForUserSpecLiveList"
                      small/>
                </div>

                <div v-else-if="typeof event.visit!=='undefined'"
                  @click="visitId=event.visit.id;"
                  @contextmenu.prevent=""
                  @mousedown.right.prevent="loadState.isSuccess ? startDrag(event) : null"
                  @mouseup.right.stop="loadState.isSuccess ? cancelDrag(event) : null"
                  class="fill-height overflow-hidden position-relative"
                >
                  <visit-calendar-view :event="event" :statuses="visitStatuses"/>
                </div>
              </template>
              <template v-slot:day-body="{ date, week }">
                <div
                  v-if="isToday"
                  class="v-current-time"
                  :class="{ first: date === week[0].date }"
                  :style="{ top: nowY }"
                ></div>
              </template>
            </v-calendar>
          </div>
        </div>
      </v-sheet>
      <div v-if="loadState.isLoading" class="position-absolute full-width" style="z-index:10">
        <v-progress-linear indeterminate color="primary" height="8"/>
      </div>
      <div>
        <v-card class="position-absolute" style="right:0;top:0;z-index:100" :height="height" :width="typeof navMenuRight==='undefined' ? 56 : 400" tile>
          <v-row no-gutters>
            <v-col cols="auto" class="border-right">
              <v-sheet width="56" :height="height">
                <v-list-item-group v-model="navMenuRight" color="primary">
                  <v-list-item class="py-2">
                    <v-icon :color="navMenuRight===0 ? 'primary' : ''">mdi-calendar-question</v-icon>
                  </v-list-item>
                  <v-list-item class="py-2">
                    <v-icon :color="navMenuRight===1 ? 'primary' : ''">mdi-calendar-today</v-icon>
                  </v-list-item>
                  <v-list-item class="py-2">
                    <span class="counter">{{ visitsMove.length }}</span>
                    <v-icon :color="navMenuRight===2 ? 'primary' : ''">mdi-calendar-account</v-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-sheet>
            </v-col>
            <v-col style="position: relative;">
              <visit-plans
                  v-if="navMenuRight===0 || navMenuRight===1"
                  ref="visitPlans"
                  :height="height"
                  :users="users"
                  :specs="specs"
                  :filterUserSpec.sync="visitPlansFilterUserSpec"
                  :filterSpec.sync="visitPlansFilterSpec"
                  :daySelected="daySelected"
                  :liveList="navMenuRight===1"
                  :dataFromCaller.sync="dataFromCaller"
                  :branches="comBranchsSelected"
                  @visitPlansUpdated="visitPlansLoad();"
                  @cancel="navMenuRight=undefined;visitPlansFilterUserSpec=null"
                  @visitCreate="visitCreateByVisitPlan"
                  @clear-data-from-caller="clearDataFromCaller"
              />

              <visit-move 
              v-if="navMenuRight===2" 
              :visits="visitsMove" 
              :height="height"
              @visit-open="(v)=>visitId=v" 
              @move="(v)=>{visitId=null;visitMove=v}"
              @cancel="navMenuRight=undefined;visitPlansFilterUserSpec=null"
              />
            </v-col>
          </v-row>
        </v-card>
      </div>
      
      <alert-view  v-if="loadState.isSuccess && cats.length===0" icon="mdi-information-outline" text="Не найдено" />

      <visit
          :visitId.sync="visitId"
          :users-specs="users"
          :specs="specs"
          :servicesId="userspecServices.map(v=>v.id)"
          @visitCreate="visitCreateByVisitPlan"
          @move="(v)=>{visitId=null;visitMove=v}"
      />
      <visit-mark :state="visitMarkState"  @cancel="visitMarkState.modalClose();load()"/>
      <visit-create
          :state="visitCreateState"
          :dataFromCaller.sync="dataFromCaller"
          @cancel="visitCreateState.modalClose()"
          @success="visitCreated"
          @clear-data-from-caller="clearDataFromCaller"
          />    
    </v-col>
  </v-row>
</template>


<script>
import moment from "moment";
import State from "@/plugins/state";
import axios from "axios";
import Api from "@/Api";
import VisitCalendarView from "@/views/Visit/VisitCalendarView";
import VisitMark from "@/views/Visit/VisitMark";
import Visit from "@/views/Visit/Visit";
import UserSpecPlanCalendarView from "@/views/UserSpec/UserSpecPlanCalendarView";
import AlertView from "@/components/AlertView";
import VisitCreate from "@/views/Visit/VisitCreate";
import VisitPlans from "@/views/Visit/VisitPlans";
import VisitMove from "@/views/Visit/VisitMove";
import ServiceSelect from "@/views/Service/components/ServiceSelect";
import SpecSelect from "@/views/Spec/components/SpecSelect";
import DatePicker from "@/components/DatePicker";
import CallPhone from "@/components/CallPhone.vue";
import VisitStatus from "@/components/VisitStatus";
import UserSelect from "@/views/User/components/UserSelect";
import ComBranchAutocompleteMultiple from "@/componentsV2/custom/ComBranchAutocompleteMultiple.vue";
import {Entity_UserSpecPlan,Entity_UserSpec, Entity_User, Entity_Spec, Entity_ComPlace, Entity_ComRoom, Entity_ComFloor, Entity_ComBuilding, Entity_ComBranch} from "../../../EntityStoreCacheService";

export default {
  components: {
    ComBranchAutocompleteMultiple,
    VisitPlans, 
    VisitMove, 
    VisitCreate, 
    AlertView, 
    UserSpecPlanCalendarView, 
    Visit, 
    VisitCalendarView, 
    VisitMark, 
    ServiceSelect, 
    DatePicker, 
    CallPhone,
    VisitStatus, 
    SpecSelect,UserSelect},
  data: () => ({
    daySelected: moment(new Date()).format('YYYY-MM-DD'),
    zoomSlide:50,
    height: 600,
    width: 1000,
    

    loadState: new State(),

    comBranchs:[],
    // comBranchsText:null,
    comBranchsSelected:[],

    specs:[],
    // specsText:null,
    specsSelected:[],

    users:[],
    usersAllows:[],
    //usersText:null,
    usersSelected:[],
    userspecServices:[],

    visitStatusSelected:[],
    visitStatuses:Api.model.Visit.statuses,
    visitStatusText:null,
    statusActive: false,
    
    visitId:null,
    visitMove:null,

    userSpecPlans:[],

    comRooms:[],
    comPlaces:[],
    comPlacesShows:[],
    cats:[],
    events:[],
    beginTime:'00:00',
    endTime:'00:00',
    intervalCountTime:0,
    eventDrag:null,

    lockNew:false,
    visitMarkState: new State(),
    visitCreateState: new State(),
    visitCreateVisitPlan:null,

    navMenuRight:undefined,

    visitPlansState: new State(),
    visitPlans:[],
    visitPlansFilterUserSpec:null,
    visitPlansFilterSpec:null,

    refreshTimer:null,
    timeLine:null,

    ready: false,//line for the current time

    dataFromCaller: {
      phone: null,
      user: null,
      type:null,
      visitServices: []
    },
    usersSelectedEvents: [],
    usersSelectedEventsColors: {},
    listOrGrid: false,
    visits:[],
    visitsMove:[],
    statuses:Api.model.Visit.statuses,
    scrollTop: 0,
    forcedVisitDuration:false
  }),
  beforeMount(){
    this.zoomSlide = typeof localStorage.zoomCalendar==='undefined' ? 50 : localStorage.zoomCalendar;
  },
  mounted(){
    this.$refs.calendar.$el.addEventListener("scroll", this.handleScroll);
    this.statusActive = true;
    this.visitStatusSelected = this.$activeStatuses;
    this.load();
    this.resize();
    window.addEventListener('resize', ()=>{
      this.resize();
    }, true);

    this.ready = true//line for the current time
    this.timeLine = setInterval(() => this.cal.updateTimes(), 60 * 1000) //line for the current time

    this.refreshTimer = setInterval(()=>{
      this.load(true);
    }, 60000, 60000);
  },
  beforeDestroy(){
    clearInterval(this.refreshTimer);
    clearInterval(this.timeLine);
  },
  computed:{
    cal () {
        return this.ready ? this.$refs.calendar : null//line for the current time
    },
    nowY () {
        return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'//line for the current time
    },
    isToday(){
      return this.daySelected===moment(new Date()).format('YYYY-MM-DD');
    }
  },
  watch:{
    zoomSlide(){
      localStorage.zoomCalendar = this.zoomSlide;
    },
    '$route': {
      handler: function() {   
        if (Object.keys(this.$router.currentRoute.query).length !== 0) {
          let visitPlanId = this.$router.currentRoute.query.visitPlanId;
          let type = this.$router.currentRoute.query.type;
          let visitServices = [];

          if (typeof this.$router.currentRoute.query.visitServices !== 'undefined') {
            visitServices = this.$router.currentRoute.query.visitServices.split(',');
          }

          this.clearDataFromCaller()
      
          if (typeof this.$router.currentRoute.query.userId !== 'undefined') {
            this.loadState.stateLoading();
            Api.graphql(`
              query Query(
                $id: Int
              ){
                User(
                  id:$id
                ){
                  id
                  firstName
                  lastName
                  secondName
                }
              }
            `, {
                id:parseInt(this.$router.currentRoute.query.userId)
              }, (data)=>{             
                if (typeof type !== 'undefined'&&type === 'visitPlan'){
                  this.visitCreateVisitPlan = {user:data.User[0],id:visitPlanId} //create visit from userProfile visitPlans
                } else {
                  this.dataFromCaller.user = data.User[0] //create visit from caller
                  this.visitCreateVisitPlan = {user:this.dataFromCaller.user}

                  if (visitServices.length>0) //visitServices 
                   this.dataFromCaller.visitServices = visitServices;
                }
                this.loadState.stateSuccess()
              }, (e)=>{
                this.loadState.stateError(e)
              });
          }

          if (typeof this.$router.currentRoute.query.type !== 'undefined'&&this.$router.currentRoute.query.type  !==  null&&this.$router.currentRoute.query.type !== 'visitPlan'){
            this.navMenuRight = 1  
            if(this.$router.currentRoute.query.type === 'waiting-list') {
              this.dataFromCaller.type = 'waiting-list'
            } else {
              this.dataFromCaller.type = 'live-list'
            }
          }

          if (typeof this.$router.currentRoute.query.phone !== 'undefined') {
            this.dataFromCaller.phone = this.$router.currentRoute.query.phone
            this.visitCreateVisitPlan = {user:{firstName:'+'+this.dataFromCaller.phone,lastName:'',secondName:''}}
          }

          if (typeof this.$router.currentRoute.query.visitMove !== 'undefined') {
            Api.service.visit.visit(this.$router.currentRoute.query.visitMove, v=> {
              this.visitMove = v;
              }, error => {
                this.loadState.stateError();
                console.log(error)
            });
          }

          this.$router.replace({ path: this.$route.path, query: {} });
        }
      },
      immediate: true
    },
    daySelected(){
      this.getUserSelectedEvents();
      this.load();
    },
    comBranchsSelected(){
      this.getUserSelectedEvents();
      this.load();
    },
    specsSelected(){
      this.getUserSelectedEvents();
      this.load();
    },
    usersSelected(){
      this.getUserSelectedEvents();
      this.load();
    },
    visitStatusSelected(){
      if (this.visitStatusSelected.some(value => this.$inactiveStatuses.includes(value))) {
        this.statusActive = false;
      }
      this.load();
    },
    visitId(){
      if(this.visitId===null)
        this.load();
    },
    loadState: {
      deep: true,
      handler() {
        if (this.loadState.isSuccess){
          this.handleScroll
        }
      },
    },
  },
  methods: {
    handleScroll() {
        this.scrollTop = this.$refs.calendar.$el.scrollTop;
    },
    load(isFullUpdate=false){
      this.loadState.stateLoading();
      this.visitPlansLoad();
      this.resize();

      let url1 = Api.host+'/userSpecPlan/';
      this.comBranchsSelected.forEach((v)=>{
        url1 +=(url1.indexOf('?')===-1 ? '?' : '&')+'comBranch[]='+v.id;
      });
      url1+=(url1.indexOf('?')===-1 ? '?' : '&')+'begin='+this.daySelected;
      url1+=(url1.indexOf('?')===-1 ? '?' : '&')+'end='+moment(this.daySelected, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
      this.userspecServices.forEach((v)=>{
        url1+=(url1.indexOf('?')===-1 ? '?' : '&')+'service[]='+v.id;
      });

      let url2 = Api.host+'/visit/';
      this.comBranchsSelected.forEach((v)=>{
        url2 +=(url2.indexOf('?')===-1 ? '?' : '&')+'comBranch[]='+v.id;
      });
      url2+=(url2.indexOf('?')===-1 ? '?' : '&')+'begin='+this.daySelected;
      url2+=(url2.indexOf('?')===-1 ? '?' : '&')+'end='+moment(this.daySelected, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');

      axios.all([
        this.comBranchs.length>0 && !isFullUpdate ? null : axios.get(Api.host+'/comBranch/'),
        this.comRooms.length>0 && !isFullUpdate ? null : axios.get(Api.host+'/comRoom/'),
        this.specs.length>0 && !isFullUpdate ? null : axios.get(Api.host+'/spec/'),
        this.users.length>0 && !isFullUpdate ? null : axios.get(Api.host+'/user/?type=spec&onPage=100000'),
        axios.get(url1),
        axios.get(url2)
      ]).then(axios.spread((dataComBranchs, dataComRooms, dataSpecs, dataUsers, dataUserSpecPlans, dataVisits) => {
        //comBranchs
        if(dataComBranchs!==null) this.comBranchs = dataComBranchs.data;

        let beginTime = moment('23:59', 'HH:mm');
        let endTime = moment('00:00', 'HH:mm');
        //не работает из-за того что два филиала
        let index1 = parseInt(moment(this.daySelected, 'YYYY-MM-DD').format('e'));

        this.comBranchs.forEach((comBranch)=>{
          comBranch.workPlan.forEach(function(value, index){
            if (index===index1){
              let f = moment(value.begin, 'HH:mm');
              let e = moment(value.end, 'HH:mm');
              if(beginTime.unix()>f.unix()) beginTime = f;
              if(endTime.unix()<e.unix()) endTime = e;
            }
          });
        });
        this.beginTime = beginTime.format('HH:mm');
        this.endTime = endTime.format('HH:mm');
        this.intervalCountTime = moment.duration(moment(this.endTime, 'HH:mm').diff(moment(this.beginTime, 'HH:mm'))).asHours()*2;

        //comRooms
        if(dataComRooms!==null) this.comRooms = dataComRooms.data;

        this.comPlacesShows = [];
        if(this.comBranchsSelected.length>0){
          this.comBranchsSelected.forEach((comBranch)=>{
            this.comRooms.forEach((comRoom)=>{
              if(comRoom.comFloor.comBuilding.comBranch.id==comBranch.id){
                comRoom.comPlaces.forEach((comPlace)=>{
                  comPlace.comRoom = comRoom;
                  this.comPlacesShows.push(comPlace);
                });
              }
            });
          });
        } else {
          this.comRooms.forEach((comRoom)=>{
            comRoom.comPlaces.forEach((comPlace)=>{
              comPlace.comRoom = comRoom;
              this.comPlacesShows.push(comPlace);
            });
          });
        }

        //specs
        if(dataSpecs!==null) this.specs = dataSpecs.data;

        //users
        if(dataUsers!==null) this.users = dataUsers.data.data.sort((a, b) => a.lastName.localeCompare(b.lastName));
        this.users.forEach(v=>{
          v.name = v.lastName+' '+v.firstName+' '+v.secondName;
        });

        // if(this.specsSelected.length>0){
        //   this.usersAllows = [];
        //   this.specsSelected.forEach(specId=>{
        //     this.users.forEach(user=>{
        //       user.userSpecs.forEach((userSpec)=>{
        //         if(userSpec.spec.id===specId)
        //           this.usersAllows.push(user);
        //       });
        //     });
        //   });
        // } else {
        //   this.usersAllows = this.users;
        // }

        //ADD PLAN CAT
        this.cats = [];
        this.resize();
        this.comPlacesShows.forEach((v)=>{
          this.cats.push({
            id:v.id+'_userSpecPlan',
            comPlace:v
          });
          this.cats.push(v);
        });


        this.events = [];
        let dayBegin = moment(this.daySelected + ' ' + this.beginTime + ':00', 'YYYY-MM-DD HH:mm:ss').toDate();
        let dayEnd = moment(this.daySelected + ' ' + this.endTime + ':00', 'YYYY-MM-DD HH:mm:ss').toDate();

        //userSpecPlans
        this.userSpecPlans = [];
        dataUserSpecPlans.data.forEach(v=>{
          if(v.type==='workVisit' || v.type==='workVisitReserve')
            this.userSpecPlans.push(v);
        });
        this.userSpecPlans.forEach((userSpecPlan)=>{
          let show = false;
          if(this.specsSelected.length>0){
            this.specsSelected.forEach((specId)=>{
              if(userSpecPlan.userSpec.spec.id===specId)
                show = true;
            });
          } else{
            show = true;
          }

          let show1 = false;
          if(this.usersSelected.length>0){
            this.usersSelected.forEach((userId)=>{
              if(userSpecPlan.userSpec.user.id===userId)
                show1 = true;
            });
          } else{
            show1 = true;
          }

          if(show && show1){
            this.events.push({
              userSpecPlan: userSpecPlan,
              start: moment(userSpecPlan.begin, 'YYYY-MM-DD HH:mm:ss').toDate(),
              end: moment(userSpecPlan.end, 'YYYY-MM-DD HH:mm:ss').toDate(),
              color: 'rgb(245 245 245)',
              timed: true,
              category: userSpecPlan.comPlace.id+'_userSpecPlan'
            });
          }
        });
        
        let userSpecPlanEventCats = [];
        this.events.forEach((event)=>{
          userSpecPlanEventCats[event.userSpecPlan.comPlace.id] = [];
        });
        this.events.forEach((event)=>{
          userSpecPlanEventCats[event.userSpecPlan.comPlace.id].push(event);
        });
        userSpecPlanEventCats.forEach((events, comPlaceId)=>{
          //first
          let intervals = [];
          events.forEach((event)=>{
            intervals.push({
              begin: moment(event.userSpecPlan.begin, 'YYYY-MM-DD HH:mm:ss'),
              end: moment(event.userSpecPlan.end, 'YYYY-MM-DD HH:mm:ss')
            });
          });

          intervals = intervals.sort((a, b)=>{
            if (a.begin>b.begin) return 1;
            if (a.begin<b.begin) return -1;
            return 0;
          });

          let noPlans = [];
          //begin
          intervals.forEach((interval)=>{
            if(noPlans.length===0){
              noPlans[0] = {
                begin:dayBegin,
                end:interval.begin.toDate()
              };
            } else{
              if(noPlans[0].begin>interval.begin)
                noPlans[0] = {
                  begin:dayBegin,
                  end:interval.begin.toDate()
                };
            }
          });

          //between
          intervals.forEach((interval, index)=>{
            if(index>0){
              noPlans.push({
                begin:intervals[index-1].end.toDate(),
                end:interval.begin.toDate()
              });
            }
          });

          //end
          noPlans.push({
            begin:intervals[intervals.length-1].end.toDate(),
            end:dayEnd
          });

          noPlans = noPlans.filter(noPlan=>noPlan.begin.getTime()!==noPlan.end.getTime());

          noPlans.forEach((noPlan)=>{
            this.events.push({
              start: noPlan.begin,
              end: noPlan.end,
              color: 'rgb(245 245 245)',
              timed: true,
              category: comPlaceId,
              type: 'noPlan'
            });
            this.events.push({
              start: noPlan.begin,
              end: noPlan.end,
              color: 'rgb(245 245 245)',
              timed: true,
              category: comPlaceId + '_userSpecPlan',
              type: 'noPlan'
            });
          });
        });

        //if comPlace day is empty
        this.comPlacesShows.forEach((comPlaceShow)=>{
          let found = false;
          this.events.forEach((event)=> {
            if(event.category===comPlaceShow.id+'_userSpecPlan')
              found = true;
          });
          if(!found){
            this.events.push({
              start: moment(this.daySelected + ' ' + this.beginTime + ':00', 'YYYY-MM-DD HH:mm:ss').toDate(),
              end: moment(this.daySelected + ' ' + this.endTime + ':00', 'YYYY-MM-DD HH:mm:ss').toDate(),
              color: 'rgb(245 245 245)',
              timed: true,
              category: comPlaceShow.id + '_userSpecPlan',
              type: 'noPlan'
            });
            this.events.push({
              start: moment(this.daySelected + ' ' + this.beginTime + ':00', 'YYYY-MM-DD HH:mm:ss').toDate(),
              end: moment(this.daySelected + ' ' + this.endTime + ':00', 'YYYY-MM-DD HH:mm:ss').toDate(),
              color: 'rgb(245 245 245)',
              timed: true,
              category: comPlaceShow.id,
              type: 'noPlan'
            });

            this.cats = this.cats.filter((v)=>{
              let comPlaceId = typeof v.comPlace==='undefined' ? v.id : v.comPlace.id;
              return comPlaceId!==comPlaceShow.id;
            });
          }
        });

        //visits
        dataVisits.data.forEach((visit)=>{
          //specs filter
          let show = false;
          if(this.specsSelected.length>0){
            this.specsSelected.forEach((specId)=>{
              if(visit.userSpec&&visit.userSpec.spec.id===specId)
                show = true;
            });
          } else{
            show = true;
          }
          //user filter
          let show1 = false;
          if(this.usersSelected.length>0){
            this.usersSelected.forEach((userId)=>{
              if(visit.userSpec) {
              if(visit.userSpec.user.id===userId)
                show1 = true;
              }
            });
          } else{
            show1 = true;
          }

          // let show2 = false;
          // if(this.visitStatusSelected.length>0){
          //   this.visitStatusSelected.forEach((visitStatusId)=>{
          //     if(visit.status===visitStatusId)
          //       show2 = true;
          //   });
          // } else{
          //   show2 = true;
          // }
          
          //status filter
          let show2 = false;
          if(this.visitStatusSelected.length>0){
            this.visitStatusSelected.forEach((v)=>{
              if(visit.status===v||visit.status==='move')
                show2 = true;
            });
          } else {
            show2 = true;
          }

          if((show && show1 && show2) || visit.mark!==null){
            visit.comPlace = this.comPlacesShows.filter(v1=>v1.id===visit.comPlace.id)[0];
            if(visit.comPlace){
              let show4 = this.events
                .filter(v=>v.category===visit.comPlace.id+'_userSpecPlan')
                .filter(v=>v.type==='noPlan')
                .filter(v=>moment(v.start)<=moment(visit.begin, 'YYYY-MM-DD HH:mm:ss'))
                .filter(v=>moment(v.end)>=moment(visit.end, 'YYYY-MM-DD HH:mm:ss'))
                .length===0;
              
              if(show4||visit.status==='move') {
                this.events.push({
                  visit: visit,
                  start: moment(visit.begin, 'YYYY-MM-DD HH:mm:ss').toDate(),
                  end: moment(visit.end, 'YYYY-MM-DD HH:mm:ss').toDate(),
                  color: 'rgb(245 245 245)',
                  timed: true,
                  category: visit.comPlace.id
                });
              }  
            }  
          }
        });

        //visits for list view
        this.visits = [];
        this.events.forEach(event=>{
          if(typeof event.visit !== 'undefined'){
            this.visits.push(event.visit);
          }
        }); 

        //visits for move
        this.visitsMove = this.visits.filter(v=>v.status==='move');

        if(!this.visitStatusSelected.includes('move'))
          this.events = this.events.filter(e=>typeof e.visit!=='undefined'&&e.visit.status!=='move'||typeof e.visit==='undefined');
   


        this.resize();
        this.loadState.stateSuccess();
      }))
      .catch(e =>{
        Api.responseError(e)
        this.loadState.stateError();
      });
    },
    visitCreated(visitId){
      this.forcedVisitDuration = false;
      
      this.visitCreateState.modalClose();
      if(visitId!==null)
        this.visitId = visitId;
      this.load();
      if(this.navMenuRight===0 || this.navMenuRight===1)
        this.$refs.visitPlans.load();
    },
    visitUpdateTime(event){
      this.loadState.stateLoading();
      axios.put(Api.host+'/visit/'+event.visit.id+'/', {
        begin:moment(event.start).format('YYYY-MM-DD HH:mm:00'),
        end:moment(event.end).format('YYYY-MM-DD HH:mm:00'),
        comPlace: event.category
      })
      .then(() =>{
        this.load();
      })
      .catch(e =>{
        Api.responseError(e)
        this.$store.commit('alertError', e.response.data.message);
        this.load();
      });
    },

    serviceSelected(v){
      this.userspecServices = v;
      this.load();
    },

    //drag
    startDrag(event){
      this.eventDrag = event;
    },
    mouseMove({ category, date, time }){   
      this.events = this.events.filter(v=>v.type!=='lockNew' && v.type!=='visitNew');
      
      if(this.eventDrag!==null) {
        let t = moment(date + ' ' + time, 'YYYY-MM-DD HH:mm');

        let diff = moment(this.eventDrag.end).diff(this.eventDrag.start, 'minutes');

        let beginTime = t.minute(t.minute() - (t.minute() % 5));
        let endTime = t.clone().add(diff, 'minutes');

        let userSpecPlans = this.userSpecPlans.filter((v) => v.comPlace.id === category.id);
        let userSpecPlan = null;
        userSpecPlans.forEach((v) => {
          if (
              category.id === v.comPlace.id &&
              beginTime >= moment(v.begin, 'YYYY-MM-DD HH:mm:ss') &&
              endTime <= moment(v.end, 'YYYY-MM-DD HH:mm:ss')
          )
            userSpecPlan = v;
        });

        if (userSpecPlan !== null) {
          this.eventDrag.category = category.id;
          this.eventDrag.start = beginTime.toDate();
          this.eventDrag.end = endTime.toDate();
        }
      } else {

  
        if(typeof category.id==='number'){
          let beginTime = moment(date+' '+time, 'YYYY-MM-DD HH:mm:ss');

          let userSpecPlans = this.userSpecPlans.filter((v)=>v.comPlace.id===category.id);
          let userSpecPlan = null;
          userSpecPlans.forEach((v)=>{
            if(
                category.id===v.comPlace.id &&
                beginTime>=moment(v.begin, 'YYYY-MM-DD HH:mm:ss') &&
                beginTime<moment(v.end, 'YYYY-MM-DD HH:mm:ss')
            )
              userSpecPlan = v;
          });
          


          if(userSpecPlan!==null){

            beginTime = beginTime.minute(beginTime.minute() - (beginTime.minute() % 5));
            let endTime = !this.forcedVisitDuration ? beginTime.clone().add(userSpecPlan.gridMinutes, 'minutes').toDate() : beginTime.clone().add(this.forcedVisitDuration, 'minutes').toDate();
            beginTime = beginTime.toDate();

            //next event start        
            let nextEventStart = false;
            for (const v of this.events) {
              if(moment(v.start).isBetween(moment(beginTime),moment(endTime))&&v.category == category.id) {
                nextEventStart = moment(v.start, 'YYYY-MM-DD HH:mm:ss');
                break;
              }
            }

            //if it's another event
            let timeOccupied = false;
            for (const v of this.events) {
              if(moment(beginTime).isBetween(moment(v.start).subtract(1, 'minutes'),moment(v.end))&&v.category == category.id) {
                timeOccupied = true;
                break;
              }
            } 

            if(!timeOccupied) {            
              if(moment(beginTime).unix()>moment().hour(0).minutes(0).seconds(0).unix()||this.lockNew){
                let userSpecPlanEnd = moment(userSpecPlan.end, 'YYYY-MM-DD HH:mm:ss');
                let diff = moment(userSpecPlanEnd).diff(moment(endTime),'m');

                this.events.push({
                  start: beginTime,
                  end: diff<0 ? userSpecPlanEnd.toDate() : nextEventStart ? nextEventStart.toDate() : endTime,
                  color: diff<0||nextEventStart ? 'error' : 'primary',
                  timed: true,
                  category: category.id,
                  comPlace: category,
                  type:this.lockNew ? 'lockNew' :'visitNew',
                  gridMinutes: userSpecPlan.gridMinutes
                });
              }
            }
          }
        }
      }
    },
    cancelDrag(event){
      this.visitUpdateTime(event);
      this.eventDrag = null;
    },

    //calendar tools
    getEventColor(event) {
      return event.color
    },
    hm(interval){
      return interval.time;
    },

    //visitPlans
    visitPlansLoad(){
      this.visitPlansState.stateLoading();
      axios.get(Api.host+'/visitPlan/?date='+this.daySelected+'&page=1&onPage=10000')
        .then((r) =>{
          this.visitPlans = r.data;
          this.visitPlansState.stateSuccess();
        })
        .catch(e =>{
          this.visitPlansState.stateError();
          Api.responseError(e)
        });
    },
    visitPlansForUserSpec(v){
      this.navMenuRight=0;
      this.visitPlansFilterUserSpec = v;
      this.visitPlansFilterSpec = v.spec;
    },
    visitPlansForUserSpecLiveList(v){
      this.navMenuRight=1;
      this.visitPlansFilterUserSpec = v;
      this.visitPlansFilterSpec = v.spec;
    },
    visitCreateByVisitPlan(v){
      this.visitCreateVisitPlan = v;
    },

    //tools
    resize(){
      this.height = window.innerHeight-64;
      this.width = (this.cats.length/2)*(200+30);
      if(this.width>0){
        this.width += 60;
        this.width += 15;
      }
    },

    setToday() {
      this.daySelected = moment(new Date()).format('YYYY-MM-DD');
      this.load();
    },
    isFinish(t) {
      if (moment(t).diff(moment(), 'minutes')<0) {
        return true
      }  
    },
    clearDataFromCaller() {
      this.dataFromCaller = {
        phone: null,
        user: null,
        type: null,
        visitServices: []
      }
      this.visitCreateVisitPlan = null
    },
    getUserSelectedEvents() {
      this.usersSelectedEvents = [];
      this.usersSelectedEventsColors = {};
      let comBranchSelected = [];

      this.comBranchsSelected.forEach(b=>{
        comBranchSelected.push(b.id);
      });

      if (this.usersSelected.length>0||this.specsSelected.length>0) {
        this.loadState.stateLoading();

        let builder = new Entity_UserSpecPlan()
        .selects(Entity_UserSpecPlan.id)
        .selects(Entity_UserSpecPlan.begin)
        .selects(Entity_UserSpecPlan.type)
        .selects(Entity_UserSpecPlan.status)
        .selects([Entity_UserSpecPlan.userSpec,Entity_UserSpec.user,Entity_User.id].join('.'))
        .selects([Entity_UserSpecPlan.userSpec,Entity_UserSpec.spec, Entity_Spec.id].join('.'))
        .selects([Entity_UserSpecPlan.comPlace,Entity_ComPlace.comRoom,Entity_ComRoom.comFloor,Entity_ComFloor.comBuilding, Entity_ComBuilding.comBranch,Entity_ComBranch.id].join('.'))
        .selects([Entity_UserSpecPlan.comPlace,Entity_ComPlace.comRoom,Entity_ComRoom.comFloor,Entity_ComFloor.comBuilding, Entity_ComBuilding.comBranch,Entity_ComBranch.color].join('.'))
     
        builder.filterAnd([Entity_UserSpecPlan.entity, Entity_UserSpecPlan.begin].join('.')+'>=?',moment(this.daySelected).subtract(31, 'days').format('YYYY-MM-DD 00:00:00'));
        builder.filterAnd([Entity_UserSpecPlan.entity, Entity_UserSpecPlan.type].join('.')+'=?','workVisit');
        builder.filterAnd([Entity_UserSpecPlan.entity, Entity_UserSpecPlan.status].join('.')+'=?','active');

        if (this.usersSelected.length>0) {
          builder.filterAnd([Entity_UserSpecPlan.entity, Entity_UserSpecPlan.userSpec, Entity_UserSpec.user,Entity_User.id].join('.')+' in (?)',[this.usersSelected.join(',')]);
        }
        if(this.specsSelected.length>0) {
          builder.filterAnd([Entity_UserSpecPlan.entity, Entity_UserSpecPlan.userSpec, Entity_UserSpec.spec, Entity_Spec.id].join('.')+' in (?)',[this.specsSelected.join(',')]);
        }
        if (comBranchSelected.length>0) {
          builder.filterAnd([Entity_UserSpecPlan.entity, Entity_UserSpecPlan.comPlace, Entity_ComPlace.comRoom, Entity_ComRoom.comFloor,Entity_ComFloor.comBuilding, Entity_ComBuilding.comBranch,Entity_ComBranch.id].join('.')+' in (?)',[comBranchSelected]);
        }


        builder.page(1).onPage(3000);

        builder.request((data)=>{
          if (data.count>0) {
            data.items.forEach(uSP=>{
              this.usersSelectedEvents.push(this.$tools.dateTimeFormat(uSP.begin, 'YYYY-MM-DD'));

              let key = this.$tools.dateTimeFormat(uSP.begin, 'YYYY-MM-DD');
              let color = uSP.comPlace.comRoom.comFloor.comBuilding.comBranch.color;
              if (typeof this.usersSelectedEventsColors[key] === 'undefined') {
                this.usersSelectedEventsColors[key] = [];
              }
              if (!this.usersSelectedEventsColors[key].includes(color)&&this.usersSelectedEventsColors[key].length<3) {
                this.usersSelectedEventsColors[key].push(color);
              }
            });
          }
          this.loadState.stateSuccess();
        }, (e)=>{
          console.error(e.response.data.message);
          this.loadState.stateError(e)
        })
      }
     
    },
    download(){
        let a = '\ufeff';
        let table = this.$refs.table.$el.querySelector('.v-data-table__wrapper table');
        table.querySelectorAll('thead th').forEach((th)=>{
          a +=th.querySelector('span').innerText+';'
        })
        a +=`
      `;
        table.querySelectorAll('tbody tr').forEach((tr)=>{
          tr.querySelectorAll('td').forEach((td)=>{
            let v = td.innerText.replace('\n', '. ').trim();
            if(v.endsWith(" Р")){
              v = v.substring(0, v.length-2)
              v = v.replace(',', '');
            }
            console.log(v)
            a +=v+';'
          })
          a +=`
      `;
        })

        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(a));
        element.setAttribute('download', 'visits_('+this.$tools.dateTimeFormat(this.daySelected, 'DD.MM.YYYY')+').csv');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);

    },
    userspecSelected(users){
      this.usersSelected = [];
      users.forEach(u=>{
        this.usersSelected.push(parseInt(u.id));
      });
    },
    VisitStatusesCheckbox(v){
      let allStatuses = this.$activeStatuses.concat(this.$inactiveStatuses);
      this.visitStatusSelected = v ? this.$activeStatuses : allStatuses;
    }
  },
}
</script>


<style type="scss">
  .v-calendar-daily__body {
    padding-bottom: 30px;
  }
  .counter {
    position: absolute;
    top: 4px;
    right: 4px;
    font-size: 12px;
    background: #1976d2;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    z-index: 9;
  }
</style>